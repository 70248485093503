import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Logo2D from "../../design/icons/logo-2d.png";
import DrawerButton from "./DrawerButton";
import CriteriaBox from "./CriteriaBox";
import alguesIcon from "../../design/icons/algues.png";
import drinkIcon from "../../design/icons/cocktail.png";
import handicapeIcon from "../../design/icons/handicape.png";
import kayakIcon from "../../design/icons/kayak.png";
import surfIcon from "../../design/icons/surf.png";
import volleyIcon from "../../design/icons/volley.png";
import { Beach } from "../../types/Beach";
import { CriteriaStates } from "../../types/CriteriaStates";

const drawerWidth = 430;

interface Props {
  window?: () => Window;
  criteriaStates: CriteriaStates;
  handleCriteriaClick: (title: string) => void;
  handleWindSortDisplay: () => void;
  beaches: Beach[];
}
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "white",
  borderRadius: "15px",
  color: "#333333",
  fontFamily: "Roboto, sans-serif",
  textAlign: "center",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
  position: "fixed",
  top: "4em",
  left: "2em",
  width: "fit-content",
  height: "fit-content",
  display: "flex",
  alignItems: "center",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: "30%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DesktopDrawer(props: Props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography noWrap component="div" fontSize="20px">
            <strong>Trouvez une plage selon vos critères 🌴</strong>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRadius: "20px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className="criteria-drawer">
          <DrawerHeader style={{ display: "flex", alignItems: "center" }}>
            <img
              src={Logo2D}
              style={{ width: "70px", height: "70px", marginLeft: "0" }}
            />
            <IconButton color="primary" onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <div className="criteria-drawer-content">
            <CriteriaBox
              titleBox={"Populaires"}
              icon1={surfIcon}
              title1={"Surf"}
              icon2={volleyIcon}
              title2={"Volley"}
              icon3={handicapeIcon}
              title3={"Handicapés"}
              subtitle="Ajouter"
              handleCriteriaClick={props.handleCriteriaClick}
            />
            <CriteriaBox
              titleBox={"Récent"}
              icon1={drinkIcon}
              title1={"Boissons"}
              icon2={alguesIcon}
              title2={"Algues"}
              icon3={kayakIcon}
              title3={"Nautiques"}
              subtitle="Ajouter"
              handleCriteriaClick={props.handleCriteriaClick}
            />
            <DrawerButton
              buttonText={"Plages à l'abris du vent"}
              onClick={() => {
                props.handleWindSortDisplay();
                setOpen(false);
              }}
            />
          </div>
        </div>
      </Drawer>
    </Box>
  );
}
