import React, { useEffect, useState } from "react";

// Components
import WindSort from "../components/weather/WindSort";
import LandingPage from "../pages/LandingPage";
import Logo from "../components/design-system/Logo";
import BeachMarker from "../components/beach/BeachMarker";
import GlobalData from "../components/weather/GlobalData";
import Drawer from "../components/drawer/Drawer";
import Map from "./Map";

// Types
import { Beach } from "../types/Beach";
import { CriteriaStates } from "../types/CriteriaStates";

// Styles
import "../App.css";
import "../components/drawer/Criteria.css";
import "mapbox-gl/dist/mapbox-gl.css";

// Data & Config
import { yeuGPS } from "../gps-data/gps";
import { apiKey } from "../weather-api/api-key";
import { beaches } from "../components/beach/BeachConfig";
import Tides from "../components/weather/Tides";
import ErrorAlert from "../components/design-system/Alert";
import Authentication from "../components/design-system/Authentication";
import { addBeachesToFirestore } from "../components/beach/BeachConfigFirebase";
import {
  fetchReactions,
  useAddReactionToBeach,
} from "../hooks/useAddReactionToBeach";
import BeachReactions from "../components/beach/BeachReactions";
import AddReactions from "../components/beach/AddReactions";
import { isMobile } from "react-device-detect";

const initialCriteriaStates: CriteriaStates = {
  surf: false,
  boissons: false,
  swim: false,
  volley: false,
  méduses: false,
  handicapés: false,
  algues: false,
  nautiques: false,
};

const Homepage = () => {
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [showWindSort, setShowWindSort] = useState<boolean>(false);
  const [displayBeaches, setDisplayBeaches] = useState<boolean>(false);
  const [windSortDisplayBeaches, setWindSortDisplayBeaches] = useState<Beach>();
  const [windDirection, setWindDirection] = useState<string>("");

  const [criteriaStates, setCriteriaStates] = useState<CriteriaStates>(
    initialCriteriaStates
  );
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  // Fetch weather data and set wind direction
  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await fetch(
          `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${yeuGPS[0]},${yeuGPS[1]}`
        );
        const data = await response.json();
        setWindDirection(data.current.wind_dir);
      } catch (error) {
        console.error("Failed to fetch weather data:", error);
      }
    };

    fetchWeatherData();
  }, [apiKey]);

  const handleCriteriaClick = (criteria: keyof CriteriaStates) => {
    setCriteriaStates((prevStates) => ({
      ...prevStates,
      [criteria]: !prevStates[criteria],
    }));
    setWindSortDisplayBeaches(undefined);
    setDisplayBeaches(false);
  };

  const handleDisplayBeaches = () => {
    setDisplayBeaches(true);
    setWindSortDisplayBeaches(undefined);
    setCriteriaStates(initialCriteriaStates);
  };

  const handleWindSortDisplayBeaches = (beach: Beach | undefined) => {
    setWindSortDisplayBeaches(beach);
    setDisplayBeaches(false);
    setCriteriaStates(initialCriteriaStates);
  };

  const handleWindSortDisplay = () => {
    setShowWindSort(true);
  };

  // Calculate matching beaches and their compatibility percentages
  const getMatchingBeaches = () => {
    return beaches
      .map((beach) => {
        const matchingCriteria = beach.criteria.filter(
          (criteria) => criteriaStates[criteria as keyof typeof criteriaStates]
        );
        const compatibilityPercentage =
          (matchingCriteria.length / Object.keys(criteriaStates).length) * 100;

        return {
          ...beach,
          matchingCriteria,
          compatibilityPercentage,
        };
      })
      .sort((a, b) => b.compatibilityPercentage - a.compatibilityPercentage);
  };

  const [addReactionsDrawerOpen, setAddReactionsDrawerOpen] = useState(false);

  const handleOpenAddReactions = () => {
    setAddReactionsDrawerOpen(true);
  };

  const sortedBeaches = getMatchingBeaches();

  return (
    <>
      {!isOnline && <ErrorAlert />}
      {showLandingPage && <LandingPage />}
      <Drawer
        criteriaStates={criteriaStates}
        handleWindSortDisplay={handleWindSortDisplay}
        handleCriteriaClick={handleCriteriaClick}
        handleDisplayBeaches={handleDisplayBeaches}
      />
      <Tides />
      <GlobalData />
      {isMobile && (
        <AddReactions
          window={undefined}
          open={addReactionsDrawerOpen}
          setOpen={setAddReactionsDrawerOpen}
        />
      )}
      {showWindSort && (
        <>
          <WindSort
            handleWindSortDisplayBeaches={handleWindSortDisplayBeaches}
            currentDirection={windDirection}
            beaches={beaches}
            onClose={() => setShowWindSort(false)}
          />
        </>
      )}
      <Map
        sortedBeaches={sortedBeaches}
        displayBeaches={displayBeaches}
        windSortDisplayBeaches={windSortDisplayBeaches}
        setShowLandingPage={setShowLandingPage}
      />
    </>
  );
};

export default Homepage;
