import React, { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Text from "../design-system/Text";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import { useAddReactionToBeach } from "../../hooks/useAddReactionToBeach";
import AddReactionsDrawerContent from "./AddReactionsDrawerContent";
import AddReactionsDrawerSubmit from "./AddReactionsDrawerSubmit";
import AddReactionsDrawerLevelChoice from "./AddReactionsDrawerLevelChoice";
import { ReactionContext } from "../../context/ReactionContext";
import CloseDrawerButton from "../design-system/CloseDrawerButton";

const Puller = styled("div")(({ theme }) => ({
  width: 60,
  height: 12,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 6,
  position: "absolute",
  top: 20,
  left: "calc(50% - 30px)",
}));

const Root = styled("div")(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
  position: "relative",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  position: "fixed", // Ensure the button is always visible
  bottom: 16,
  right: 16,
  zIndex: 1,
}));

const WhiteButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "black",
  width: "100px",
  height: "100px",
  marginBottom: "300px",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
}));

interface AddReactionsProps {
  window?: () => Window;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const AddReactions = ({ window, open, setOpen }: AddReactionsProps) => {
  const [submitOpen, setSubmitOpen] = useState<boolean>(false);
  const [selectedBeach, setSelectedBeach] = useState<string>("");
  const reactionContext = useContext(ReactionContext);
  const selectedReactions = reactionContext
    ? reactionContext?.selectedReaction
    : [];

  const [lastClickedReaction, setLastClickedReaction] = useState<string | null>(
    null
  );
  const DRAWER_HEIGHT_CLOSED = "500px";
  const DRAWER_HEIGHT_OPEN = submitOpen ? "50%" : "80%";
  const submitText = `Signaler aux autres`;

  const container = window ? () => window().document.body : undefined;
  const handleSelectBeach = (newRange: string) => {
    setSelectedBeach(newRange);
  };

  const isBeachSelected = () => {
    if (!selectedBeach || selectedBeach.trim() === "") {
      alert("Vous devez choisir une plage.");
      return false;
    }
    return true;
  };

  const handleAddReaction = async (selectedBeach: string) => {
    if (!isBeachSelected()) return;

    try {
      await useAddReactionToBeach(selectedBeach, selectedReactions);
      setOpen(false);
    } catch (error) {
      alert("Erreur lors de l'ajout de la réaction.");
    }
  };

  useEffect(() => {
    if (selectedBeach && selectedReactions.length > 0) {
      if (!submitOpen) {
        setSubmitOpen(true);
      }
    }
  }, [selectedReactions, selectedBeach, lastClickedReaction]);

  return (
    <Root>
      <DrawerHeader>
        <WhiteButton onClick={() => setOpen(true)}>
          <AddIcon sx={{ fontSize: 50 }} />
        </WhiteButton>
      </DrawerHeader>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableSwipeToOpen={true}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            height: open ? DRAWER_HEIGHT_OPEN : DRAWER_HEIGHT_CLOSED,
            borderRadius: "20px",
          },
        }}
      >
        <Puller />
        {/* <CloseDrawerButton setOpen={setOpen} /> */}

        <br />
        <div style={{ marginBottom: "0 !important" }}>
          {!submitOpen ? (
            <Text type="title" text="Que voulez vous signaler ?" />
          ) : (
            <Text type="title" text={submitText} />
          )}
        </div>
        {!submitOpen ? (
          <AddReactionsDrawerContent
            handleSelectBeach={handleSelectBeach}
            handleReactionClick={() => {}}
            selectedBeach={selectedBeach}
            handleOpenSubmit={(selectedBeach) => {
              if (selectedBeach !== "" && selectedReactions.length !== 0) {
                setSubmitOpen(true);
              }
            }}
          />
        ) : (
          <>
            <AddReactionsDrawerLevelChoice
              reactions={selectedReactions}
              handleReactionClick={() => {}}
            />
            <AddReactionsDrawerSubmit
              handleCancelAddReaction={async () => {
                setSubmitOpen(false);
                setOpen(false);
                setSelectedBeach("");
                reactionContext?.resetReactions();
              }}
              handleAddReaction={() => handleAddReaction(selectedBeach)}
            />
          </>
        )}
      </SwipeableDrawer>
    </Root>
  );
};

export default AddReactions;
