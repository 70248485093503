import React from "react";
import { styled } from "@mui/material/styles";
import Text from "../design-system/Text";
import CriteriaBox from "../drawer/CriteriaBox";
import DrawerButton from "../drawer/DrawerButton";
import SelectBeach from "./SelectBeach";

import alguesIcon from "../../design/icons/algues.png";
import windIcon from "../../design/icons/vent_noir.png";
import waveIcon from "../../design/icons/vague.png";
import fouleIcon from "../../design/icons/foule.png";
import meduseIcon from "../../design/icons/meduse.png";
import courrantIcon from "../../design/icons/courrant.png";

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#333",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  zIndex: 2,
}));

interface AddReactionsDrawerSubmitProps {
  handleCancelAddReaction: () => void;
  handleAddReaction: (selectedBeach: string) => Promise<void>;
}

const AddReactionsDrawerSubmit: React.FC<AddReactionsDrawerSubmitProps> = ({
  handleCancelAddReaction,
  handleAddReaction,
}) => {
  return (
    <StyledBox sx={{ px: 2, pb: 2, height: "50%", overflow: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          top: "50% !important",
          gap: "10px !important",
        }}
      >
        <DrawerButton
          submit
          buttonText="Annuler"
          onClick={handleCancelAddReaction}
        />
        <DrawerButton
          submit
          buttonText="Signaler"
          onClick={handleAddReaction}
        />
      </div>
    </StyledBox>
  );
};

export default AddReactionsDrawerSubmit;
