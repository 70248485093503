import React from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Beach } from "../../types/Beach";
import { Divider } from "@mui/material";
import BeachReactions from "./BeachReactions";
import DrawerButton from "../drawer/DrawerButton";
import BeachWeather from "./BeachWeather";
import CloseDrawerButton from "../design-system/CloseDrawerButton";

const Root = styled("div")(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
  position: "relative",
}));

interface BeachDrawerProps {
  window?: () => Window;
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  beach: Beach;
  onOpenAddReactions: () => void;
}

const BeachDrawer = ({
  window,
  open,
  setOpen,
  beach,
  onOpenAddReactions,
}: BeachDrawerProps) => {
  const DRAWER_HEIGHT_OPEN = "80%";

  const container = window ? () => window().document.body : undefined;

  return (
    <Root>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disableSwipeToOpen={true}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            height: open ? DRAWER_HEIGHT_OPEN : 0,
            overflowY: "auto",
            borderRadius: "20px",
          },
        }}
      >
        {/* <CloseDrawerButton setOpen={setOpen} /> */}

        <div style={{ width: "100%" }}>
          <img
            src={beach.image}
            alt="Beach"
            style={{
              width: "100%",
              height: "auto",
              display: "block",
            }}
          />
        </div>

        <h1
          style={{
            fontFamily: "Roboto, sans-serif",
            textAlign: "center",
            fontSize: "50px",
            height: "max-content",
          }}
        >
          {beach.name} ☀️
        </h1>
        <div
          style={{
            fontFamily: "Roboto, sans-serif",
            textAlign: "center",
            fontSize: "40px",
            height: "min-content",
          }}
        >
          <BeachWeather beach={beach} />
        </div>
        <Divider />
        <BeachReactions beachName={beach.name} />
        <DrawerButton
          buttonText={"Signaler un élement"}
          onClick={onOpenAddReactions}
        />
      </SwipeableDrawer>
    </Root>
  );
};

export default BeachDrawer;
