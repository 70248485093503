import React, { useEffect, useState } from "react";
import { CriteriaProvider } from "./context/CriteriaContext";
import Homepage from "./pages/Homepage";

import "./App.css";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ReactionProvider } from "./context/ReactionContext";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCxNiI1ocArXO4CoR7DTWuoviAXgV7Hr6U",
  authDomain: "beachy-d328f.firebaseapp.com",
  projectId: "beachy-d328f",
  storageBucket: "beachy-d328f.appspot.com",
  messagingSenderId: "172081687043",
  appId: "1:172081687043:web:786b44d2b6c391fb0dbc36",
  measurementId: "G-8E39RJ6SHZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  return (
    <CriteriaProvider>
      <ReactionProvider>
        <Homepage />
      </ReactionProvider>
    </CriteriaProvider>
  );
};

export default App;
