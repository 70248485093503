import { collection, addDoc } from "firebase/firestore";
import { db } from '../../firebaseConfig';

import SabiasPic from "../../design/beach-pictures/sabias-pic.jpg";
import VieillesPic from "../../design/beach-pictures/vieilles-pic.jpg";
import SapinsPic from "../../design/beach-pictures/sapins-pic.jpg";
import SouxPic from "../../design/beach-pictures/soux-pic.jpg";
import KerChalonPic from "../../design/beach-pictures/kerchalon-pic.png";
import SablesRouisPic from "../../design/beach-pictures/sablesrouis.jpg";
import BelleMaisonPic from "../../design/beach-pictures/bellemaison.jpg";
import MaraisSalesPic from "../../design/beach-pictures/maraissales-pic.jpg";
import CorbeauxPic from "../../design/beach-pictures/corbeaux-pic.png";
import CoursDuMoulinPic from "../../design/beach-pictures/coursdumoulins.png";
import RaieDeLaVAchePic from "../../design/beach-pictures/raiedelavache.png";
import ConchesPic from "../../design/beach-pictures/conches-pic.jpg";
import GournaisePic from "../../design/beach-pictures/gournaise-pic.png";
import RaieProfondePic from "../../design/beach-pictures/raieprofonde-pic.png";
import { Beach } from "../../types/Beach";

// Les plages statiques
export const beaches: Beach[] = [
  {
    name: "Plage des Sabias",
    criteria: ["boissons", "volley", "handicapés"],
    gps: [46.703, -2.376],
    compatibilityPercentage: 0,
    direction: "SE",
    image: SabiasPic,
    rate: 0,
  },
  {
    name: "Plage des Vieilles",
    criteria: ["boissons", "volley", "surf", "handicapés", "nautiques"],
    gps: [46.695, -2.312],
    compatibilityPercentage: 0,
    direction: "SE",
    image: VieillesPic,
    rate: 0,
  },
  {
    name: "Plage des Sables Rouis",
    criteria: [],
    gps: [46.704132, -2.379489],
    compatibilityPercentage: 0,
    direction: "W",
    image: SablesRouisPic,
    rate: 0,
  },
  {
    name: "Plage des Sapins",
    criteria: ["volley", "handicapés"],
    gps: [46.717349, -2.314747],
    compatibilityPercentage: 0,
    direction: "NE",
    image: SapinsPic,
    rate: 0,
  },
  {
    name: "Plage de La Belle Maison",
    criteria: [],
    gps: [46.707828521728516, -2.384],
    compatibilityPercentage: 0,
    direction: "SW",
    image: BelleMaisonPic,
    rate: 0,
  },
  {
    name: "Plage des Marais Salés",
    criteria: ["volley"],
    gps: [46.71066665649414, -2.3103582859039307],
    compatibilityPercentage: 0,
    direction: "NE",
    image: MaraisSalesPic,
    rate: 0,
  },
  {
    name: "Plage des Conches",
    criteria: [],
    gps: [46.697988546275745, -2.2886859132246595],
    compatibilityPercentage: 0,
    direction: "NE",
    image: ConchesPic,
    rate: 0,
  },
  {
    name: "Plage des Corbeaux",
    criteria: [],
    gps: [46.690389, -2.282866],
    compatibilityPercentage: 0,
    direction: "E",
    image: CorbeauxPic,
    rate: 0,
  },
  {
    name: "Plage des Soux",
    criteria: [],
    gps: [46.690643310546875, -2.324136734008789],
    compatibilityPercentage: 0,
    direction: "SE",
    image: SouxPic,
    rate: 0,
  },
  {
    name: "Plage de la Gournaise",
    criteria: [],
    gps: [46.732586, -2.378213],
    compatibilityPercentage: 0,
    direction: "N",
    image: GournaisePic,
    rate: 0,
  },
  {
    name: "Plage du Ker Chalon",
    criteria: ["volley", "handicapés"],
    gps: [46.719516, -2.335407],
    compatibilityPercentage: 0,
    direction: "N",
    image: KerChalonPic,
    rate: 0,
  },
  {
    name: "Raie de La Vache",
    criteria: [],
    gps: [46.702709, -2.375047172],
    compatibilityPercentage: 0,
    direction: "S",
    image: RaieDeLaVAchePic,
    rate: 0,
  },
  {
    name: "Plage du cours du moulin",
    criteria: [],
    gps: [46.729364726, -2.36071475],
    compatibilityPercentage: 0,
    direction: "N",
    image: CoursDuMoulinPic,
    rate: 0,
  },
  {
    name: "Plage de la Raie Profonde",
    criteria: ["volley"],
    gps: [46.715316848, -2.313518839],
    compatibilityPercentage: 0,
    direction: "NE",
    image: RaieProfondePic,
    rate: 0,
  },
];

// Fonction pour ajouter les plages à Firestore
export async function addBeachesToFirestore() {
  const beachesRef = collection(db, "beaches");

  for (const beach of beaches) {
    try {
      await addDoc(beachesRef, {
        name: beach.name,
        gps: beach.gps,
        criteria: beach.criteria,
        image: beach.image,
      });
      console.log(`${beach.name} ajouté à Firestore.`);
    } catch (e) {
      console.error("Erreur lors de l'ajout de la plage: ", e);
    }
  }
}
