import "./CriteriaCircle.css";
import { CriteriaContext } from "../../context/CriteriaContext";
import React, { useContext, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

interface CriteriaCircleProps {
  icon: string;
  title: string;
  subtitle: string;
  onClick: (title: string) => void;
}

const CriteriaCircle = ({
  icon,
  title,
  subtitle,
  onClick,
}: CriteriaCircleProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const criteriaContext = useContext(CriteriaContext);

  if (!criteriaContext) {
    throw new Error("CriteriaCircle must be used within a CriteriaProvider");
  }

  const { selectedCriteria, updateCriteria } = criteriaContext;

  useEffect(() => {
    setIsSelected(selectedCriteria.includes(title.toLowerCase()));
  }, [selectedCriteria, title]);

  const handleClick = () => {
    onClick(title.toLowerCase());
    updateCriteria(title.toLowerCase());
  };

  return (
    <div className="circle-criteria-container">
      <div
        className={`${
          isMobile
            ? `circle ${isSelected ? "selected" : ""}`
            : `circle-desktop ${isSelected ? "selected" : ""}`
        } `}
        onClick={handleClick}
      >
        <img
          src={icon}
          alt="Icon"
          style={
            isMobile
              ? { width: "75px", height: "75px" }
              : { width: "35px", height: "35px" }
          }
        />
      </div>
      <div
        className={`${isMobile ? "criteria-title" : "criteria-title-desktop"}`}
      >
        {title}
      </div>
      <div
        className={`${
          isMobile ? "criteria-subtitle" : "criteria-subtitle-desktop"
        }`}
      >
        {isSelected ? "Enlever" : subtitle}
      </div>
    </div>
  );
};

export default CriteriaCircle;
