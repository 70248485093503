import React, { useEffect, useState } from "react";
import "./BeachPopup.css";
import { isMobile } from "react-device-detect";
import { Beach } from "../../types/Beach";
import BeachWeather from "./BeachWeather";
import { Chip } from "@mui/material";
import BeachReactions from "./BeachReactions";
import BeachDrawer from "./BeachDrawer";
import AddReactions from "./AddReactions";

interface BeachPopupProps {
  beach: Beach;
}

const BeachPopup = ({ beach }: BeachPopupProps) => {
  const [beachDrawerOpen, setBeachDrawerOpen] = useState(false);
  const [addReactionsDrawerOpen, setAddReactionsDrawerOpen] = useState(false);

  const handleOpenAddReactions = () => {
    setAddReactionsDrawerOpen(true);
  };

  useEffect(() => {
    addReactionsDrawerOpen && setBeachDrawerOpen(false);
  }, [beachDrawerOpen]);

  return (
    <div
      className={`${isMobile ? "popup" : "popup-desktop"}`}
      onClick={() => setBeachDrawerOpen(true)}
    >
      <div className={`${isMobile ? "popup-photo" : "popup-photo-desktop"}`}>
        <img
          className={`${isMobile ? "popup-photo" : "popup-photo-desktop"}`}
          src={beach.image}
          alt="Beach"
        />
      </div>
      <div className={`${isMobile ? "popup-text" : "popup-text-desktop"}`}>
        <div className={`${isMobile ? "beach-name" : "beach-name-desktop"}`}>
          {beach.name}
        </div>
        <div
          className={`${
            isMobile ? "beach-description" : "beach-description-desktop"
          }`}
        >
          <BeachWeather beach={beach} />
        </div>
        <div className="chips-container">
          {beach.criteria.map((criterion) =>
            isMobile ? (
              <Chip
                key={criterion}
                label={criterion}
                size="medium"
                sx={{
                  fontSize: "1.6rem",
                  height: "48px",
                  margin: "0.2em",
                  padding: "0 12px",
                }}
              />
            ) : (
              <Chip
                key={criterion}
                label={criterion}
                size="small"
                sx={{
                  margin: "0.2em",
                  padding: "0 12px",
                }}
              />
            )
          )}
        </div>
      </div>

      {isMobile && (
        <>
          <BeachDrawer
            open={beachDrawerOpen}
            setOpen={setBeachDrawerOpen}
            beach={beach}
            onOpenAddReactions={handleOpenAddReactions}
          />

          <AddReactions
            window={undefined}
            open={addReactionsDrawerOpen}
            setOpen={setAddReactionsDrawerOpen}
          />
        </>
      )}
    </div>
  );
};

export default BeachPopup;
