import "./CriteriaCircle.css";
import React, { useContext, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { ReactionContext } from "../../context/ReactionContext";

interface ReactionCircleProps {
  icon: string;
  title: string;
  subtitle: string;
  onClick: (title: string) => void;
}

const ReactionCircle = ({
  icon,
  title,
  subtitle,
  onClick,
}: ReactionCircleProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const reactionContext = useContext(ReactionContext);

  if (!reactionContext) {
    throw new Error("ReactionCircle must be used within a ReactionProvider");
  }

  const { selectedReaction, updateReaction, updateReactionLevel } =
    reactionContext;

  const current_title = title.toLowerCase();

  useEffect(() => {
    const last_item_reaction = selectedReaction[selectedReaction.length - 1];
    if (last_item_reaction) {
      setIsSelected(last_item_reaction.includes(current_title));
    }
  }, [selectedReaction, title]);

  const handleClick = () => {
    onClick(current_title);
    if (
      current_title === "faible" ||
      current_title === "moyen" ||
      current_title === "important"
    ) {
      updateReactionLevel(current_title);
    } else {
      updateReaction(current_title);
    }
  };

  return (
    <div className="circle-criteria-container">
      <div
        className={`${
          isMobile
            ? `circle ${isSelected ? "selected" : ""}`
            : `circle-desktop ${isSelected ? "selected" : ""}`
        } `}
        onClick={handleClick}
      >
        <img
          src={icon}
          alt="Icon"
          style={
            isMobile
              ? { width: "75px", height: "75px" }
              : { width: "35px", height: "35px" }
          }
        />
      </div>
      <div
        className={`${isMobile ? "criteria-title" : "criteria-title-desktop"}`}
      >
        {title}
      </div>
      <div
        className={`${
          isMobile ? "criteria-subtitle" : "criteria-subtitle-desktop"
        }`}
      >
        {isSelected ? "Enlever" : subtitle}
      </div>
    </div>
  );
};

export default ReactionCircle;
