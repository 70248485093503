import React from "react";
import { styled } from "@mui/material/styles";
import CriteriaBox from "../drawer/CriteriaBox";

import alguesIcon from "../../design/icons/algues.png";
import windIcon from "../../design/icons/vent_noir.png";
import waveIcon from "../../design/icons/vague.png";
import fouleIcon from "../../design/icons/foule.png";
import meduseIcon from "../../design/icons/meduse.png";
import courantIcon from "../../design/icons/courrant.png";
import ReactionBox from "../drawer/ReactionBox";

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#333",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  zIndex: 2,
}));

interface AddReactionsDrawerLevelChoiceProps {
  reactions: string[];
  handleReactionClick: (reaction: string) => void;
}

const AddReactionsDrawerLevelChoice: React.FC<
  AddReactionsDrawerLevelChoiceProps
> = ({ reactions, handleReactionClick }) => {
  const getIcon = () => {
    switch (reactions[0]) {
      case "vagues":
      case "vaguesfaible":
      case "vaguesmoyen":
      case "vaguesimportant":
        return waveIcon;

      case "algues":
      case "alguesfaible":
      case "alguesmoyen":
      case "alguesimportant":
        return alguesIcon;

      case "vent":
      case "ventfaible":
      case "ventmoyen":
      case "ventimportant":
        return windIcon;

      case "foule":
      case "foulefaible":
      case "foulemoyen":
      case "fouleimportant":
        return fouleIcon;

      case "courant":
      case "courantfaible":
      case "courantmoyen":
      case "courantimportant":
        return courantIcon;

      case "méduses":
      case "médusesfaible":
      case "médusesmoyen":
      case "médusesimportant":
        return meduseIcon;

      default:
        return;
    }
  };

  return (
    <StyledBox sx={{ px: 2, pb: 2, height: "100%", overflow: "auto" }}>
      <div style={{ marginTop: "-3% !important" }}>
        {
          <ReactionBox
            titleBox=""
            icon1={getIcon()}
            title1="Faible"
            icon2={getIcon()}
            title2="Moyen"
            icon3={getIcon()}
            title3="Important"
            handleCriteriaClick={(title) => {
              const newTitle = reactions[0] + title;
              handleReactionClick(newTitle); // TODO: Clean this.
            }}
            subtitle=""
          />
        }
      </div>
    </StyledBox>
  );
};

export default AddReactionsDrawerLevelChoice;
