import React from "react";
import { styled } from "@mui/material/styles";
import ReactionBox from "../drawer/ReactionBox";
import SelectBeach from "./SelectBeach";

import alguesIcon from "../../design/icons/algues.png";
import windIcon from "../../design/icons/vent_noir.png";
import waveIcon from "../../design/icons/vague.png";
import fouleIcon from "../../design/icons/foule.png";
import meduseIcon from "../../design/icons/meduse.png";
import courrantIcon from "../../design/icons/courrant.png";

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#333",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  zIndex: 2,
}));

interface AddReactionsDrawerContentProps {
  handleSelectBeach: (beach: string) => void;
  handleReactionClick: (reaction: string) => void;
  handleOpenSubmit: (selectedBeach: string) => void;
  selectedBeach: string;
}

const AddReactionsDrawerContent: React.FC<AddReactionsDrawerContentProps> = ({
  handleSelectBeach,
  handleReactionClick,
  handleOpenSubmit,
  selectedBeach,
}) => {
  return (
    <StyledBox sx={{ px: 2, pb: 2, height: "100%", overflow: "auto" }}>
      <SelectBeach onSelectChange={handleSelectBeach} />
      <ReactionBox
        titleBox=""
        icon1={alguesIcon}
        title1="Algues"
        icon2={windIcon}
        title2="Vent"
        icon3={waveIcon}
        title3="Vagues"
        handleCriteriaClick={(title) => {
          handleReactionClick(title);
          handleOpenSubmit(selectedBeach);
        }}
        subtitle="Signaler"
      />
      <ReactionBox
        titleBox=""
        icon1={fouleIcon}
        title1="Foule"
        icon2={meduseIcon}
        title2="Méduses"
        icon3={courrantIcon}
        title3="Courant"
        handleCriteriaClick={(title) => {
          handleReactionClick(title);
          handleOpenSubmit(selectedBeach);
        }}
        subtitle="Signaler"
      />
    </StyledBox>
  );
};

export default AddReactionsDrawerContent;
