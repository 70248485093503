import React, { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  DocumentData,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

export type Reaction = {
  timestamp: Timestamp;
  reactions: string;
};

interface BeachReactionsProps {
  beachName: string;
}

const BeachReactions: React.FC<BeachReactionsProps> = ({ beachName }) => {
  const [reactionsCounts, setReactionsCounts] = useState<{
    [reactions: string]: number;
  }>({});

  useEffect(() => {
    // Référence à la collection des réactions de la plage spécifiée
    const reactionsRef = collection(db, `beaches/${beachName}/reactions`);

    // Écoute des changements en temps réel
    const unsubscribe = onSnapshot(reactionsRef, (snapshot) => {
      const reactions: Reaction[] = snapshot.docs.map((doc) => ({
        reactions: doc.data().reactions,
        timestamp: doc.data().timestamp,
      }));

      // Compte les occurrences de chaque réactions
      const counts: { [reactions: string]: number } = reactions.reduce(
        (acc, reaction) => {
          acc[reaction.reactions] = (acc[reaction.reactions] || 0) + 1;
          return acc;
        },
        {} as { [reactions: string]: number }
      );

      setReactionsCounts(counts);
    });

    // Nettoyage lors du démontage du composant
    return () => unsubscribe();
  }, [beachName]);

  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        marginBottom: "120px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h6
          style={{
            fontFamily: "Roboto, sans-serif",
            textAlign: "center",
            fontSize: "30px",
            height: "min-content",
          }}
        >
          Réactions en direct des utilisateurs
        </h6>

        {/* Affichage des réactions ou message si vide */}
        {Object.keys(reactionsCounts).length === 0 ? (
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontStyle: "italic",
              color: "gray",
            }}
          >
            Pas de réactions pour le moment.
          </p>
        ) : (
          <Stack direction="row" spacing={2} flexWrap="wrap">
            {Object.entries(reactionsCounts).map(([reactions, count]) => (
              <Chip
                key={reactions}
                label={`${reactions} ${count}`}
                size="medium"
                sx={{
                  fontSize: "1.6rem",
                  height: "48px",
                  margin: "0.2em",
                  padding: "0 12px",
                }}
              />
            ))}
          </Stack>
        )}
      </div>
    </div>
  );
};

export default BeachReactions;
