import "./CriteriaBox.css";

import React from "react";

import CriteriaCircle from "./CriteriaCircle";
import { isMobile } from "react-device-detect";

interface CriteriaBoxProps {
  titleBox: string;
  icon1: string;
  title1: string;
  icon2: string;
  title2: string;
  icon3: string;
  title3: string;
  subtitle: string;
  handleCriteriaClick: (title: string) => void;
}

const CriteriaBox = ({
  titleBox,
  icon1,
  title1,
  icon2,
  title2,
  icon3,
  title3,
  subtitle,
  handleCriteriaClick,
}: CriteriaBoxProps) => {
  return (
    <>
      <div
        className={`${
          isMobile
            ? "criteria-container-description"
            : "criteria-container-description-desktop"
        }`}
      >
        {titleBox}
      </div>
      <div
        style={{ whiteSpace: "pre-line" }}
        className={`${
          isMobile ? "criteria-container" : "criteria-container-desktop"
        }`}
      >
        <CriteriaCircle
          icon={icon1}
          title={title1}
          subtitle={subtitle}
          onClick={handleCriteriaClick}
        />
        <CriteriaCircle
          icon={icon2}
          title={title2}
          subtitle={subtitle}
          onClick={handleCriteriaClick}
        />
        <CriteriaCircle
          icon={icon3}
          title={title3}
          subtitle={subtitle}
          onClick={handleCriteriaClick}
        />
      </div>
    </>
  );
};

export default CriteriaBox;
