import { useState, useEffect } from "react";

// Type pour une marée
type Tide = {
  type: string;
  time: string;
  height: string;
  coef: string;
};

// Type pour les informations d'une journée
type TideDay = {
  date: string;
  tides: Tide[];
  moon_phase: string;
  saint: string;
  sunrise: string;
  sunset: string;
};

// Types pour le retour du hook
type UseTidesDatasResult = {
  tideDay: TideDay | null;
  tideWeek: TideDay[];
};

// Fonction utilitaire pour obtenir le nom du jour en français
function getDayNameInFrench(date: Date): string {
  const options: Intl.DateTimeFormatOptions = { weekday: "long" };
  return new Intl.DateTimeFormat("fr-FR", options).format(date);
}

// Fonction utilitaire pour obtenir le mois en français
function getMonthNameInFrench(date: Date): string {
  const options: Intl.DateTimeFormatOptions = { month: "long" };
  return new Intl.DateTimeFormat("fr-FR", options).format(date);
}

// Hook pour récupérer les données de marée
export function useTidesDatas(): UseTidesDatasResult {
  const [tideDay, setTideDay] = useState<TideDay | null>(null);
  const [tideWeek, setTideWeek] = useState<TideDay[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const currentMonth = getMonthNameInFrench(new Date());
        // const currentMonth = "aout";
        const currentYear = new Date().getFullYear();

        // Construire le nom de fichier JSON basé sur le mois actuel
        // const jsonFileName = `tides_${currentMonth.toLowerCase()}_${currentYear}.json`;
        const jsonFileName = "tides_octobre_2024.json";

        // Utilisation du bon chemin pour accéder au fichier JSON
        const response = await fetch(`/${jsonFileName}`); // Notez le `/` au début du chemin
        // const response = await fetch(`/tides_juillet_2024.json`); // Notez le `/` au début du chemin

        if (!response.ok) {
          throw new Error(
            `Erreur lors de la récupération du fichier JSON : ${response.statusText}`
          );
        }

        const data: TideDay[] = await response.json();
        const today = new Date();
        const todayDayName = getDayNameInFrench(today).toLowerCase();
        const todayDate = `${todayDayName} ${today.getDate()}`.toLowerCase();
        const weekData: TideDay[] = [];

        // Filtrer les données pour le jour courant
        const currentDayData =
          data.find((day) => day.date === todayDate) || null;

        // Filtrer les données pour les 7 jours à venir
        for (let i = 0; i < 7; i++) {
          const futureDate = new Date(today);
          futureDate.setDate(today.getDate() + i);
          const futureDayName = getDayNameInFrench(futureDate).toLowerCase();
          const futureDateString =
            `${futureDayName} ${futureDate.getDate()}`.toLowerCase();

          const futureDayData = data.find(
            (day) => day.date === futureDateString
          );

          if (futureDayData) {
            weekData.push(futureDayData);
          }
        }

        setTideDay(currentDayData);
        setTideWeek(weekData);
      } catch (error) {
        console.error(
          "Erreur lors du chargement des données des marées :",
          error
        );
      }
    };

    fetchData();
  }, []); // Ne dépend que des données externes au hook

  return { tideDay, tideWeek };
}
