import React, { createContext, useState, ReactNode } from "react";

interface ReactionContextType {
  selectedReaction: string[];
  updateReaction: (reaction: string) => void;
  updateReactionLevel: (reaction: string) => void;
  resetReactions: () => void;
}

const ReactionContext = createContext<ReactionContextType | undefined>(
  undefined
);

interface reactionProviderProps {
  children: ReactNode;
}

const ReactionProvider = ({ children }: reactionProviderProps) => {
  const [selectedReaction, setselectedReaction] = useState<string[]>([]);

  const updateReaction = (reaction: string) => {
    setselectedReaction([reaction]);
  };

  const updateReactionLevel = (reaction: string) => {
    setselectedReaction((prevReaction) => {
      // Vérifie si la réaction est déjà présente
      if (prevReaction.includes(reaction)) {
        // Retire la réaction si elle est déjà présente
        return prevReaction.filter((c) => c !== reaction);
      }

      // Ajoute la nouvelle réaction
      const newReaction = [...prevReaction];

      if (newReaction.length === 2) {
        // Remplace le deuxième élément (index 1) par la nouvelle réaction
        newReaction[1] = reaction;
      } else {
        // Ajoute la nouvelle réaction si le tableau contient moins de 2 éléments
        newReaction.push(reaction);
      }

      return newReaction;
    });
  };

  const resetReactions = () => {
    setselectedReaction([]);
  };

  const contextValue: ReactionContextType = {
    selectedReaction,
    updateReaction,
    updateReactionLevel,
    resetReactions,
  };

  return (
    <ReactionContext.Provider value={contextValue}>
      {children}
    </ReactionContext.Provider>
  );
};

export { ReactionContext, ReactionProvider };
