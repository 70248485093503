import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCxNiI1ocArXO4CoR7DTWuoviAXgV7Hr6U",
    authDomain: "beachy-d328f.firebaseapp.com",
    projectId: "beachy-d328f",
    storageBucket: "beachy-d328f.appspot.com",
    messagingSenderId: "172081687043",
    appId: "1:172081687043:web:786b44d2b6c391fb0dbc36",
    measurementId: "G-8E39RJ6SHZ",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
