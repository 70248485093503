import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import { beaches } from "./BeachConfigFirebase";

interface SelectBeachProps {
  onSelectChange: (selectedRange: string) => void; // Fonction callback pour envoyer la valeur au parent
}

const SelectBeach: React.FC<SelectBeachProps> = ({ onSelectChange }) => {
  const [selectedRange, setSelectedRange] = useState<string>("");

  const handleRangeChange = (event: SelectChangeEvent<string>) => {
    const rangeValue = event.target.value;
    setSelectedRange(rangeValue);
    onSelectChange(rangeValue);
  };

  return (
    <Box
      sx={{
        top: 0,
        minWidth: 300,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "100px",
      }}
    >
      <FormControl fullWidth sx={{ maxWidth: 800 }}>
        {!selectedRange && (
          <InputLabel
            style={{
              fontSize: "36px",
              color: "black",
              textAlign: "center",
            }}
            id="range-select-label"
          >
            Choisir une plage
          </InputLabel>
        )}
        <Select
          style={{
            borderRadius: "25px",
            padding: "50px",
            backgroundColor: "#f6f6f6",
            height: "80px",
            fontSize: "28px",
            fontWeight: "700",
            color: "black",
          }}
          label=""
          id="range-select"
          value={selectedRange}
          onChange={handleRangeChange}
        >
          {beaches.map((beach) => (
            <MenuItem
              key={beach.name}
              value={beach.name}
              style={{
                fontSize: "28px",
                color: "black",
              }}
            >
              {beach.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectBeach;
