import { collection, addDoc, serverTimestamp, query, getDocs, orderBy, where, DocumentData } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Timestamp } from "firebase/firestore";

// Fonction pour ajouter une réaction à une plage
export async function useAddReactionToBeach(name: string, reactions: string[]) {
  try {
    // Crée une référence à la sous-collection des réactions de la plage spécifiée
    const reactionsRef = collection(db, `beaches/${name}/reactions`);

    // Ajoute la réaction avec un timestamp
    await addDoc(reactionsRef, {
      reactions: reactions,
      timestamp: serverTimestamp(), // Ajout de l'horodatage actuel
    });

    console.log("Réaction ajoutée avec succès.");
  } catch (e) {
    console.error("Erreur lors de l'ajout de la réaction : ", e);
  }
}

// Fonction pour récupérer les réactions des dernières 24 heures
export async function fetchReactions(beachName: string): Promise<DocumentData[]> {
  try {
    // Référence à la collection des réactions de la plage
    const reactionsRef = collection(db, `beaches/${beachName}/reactions`);

    // Calcul de la limite de 24 heures
    const now = Timestamp.now();
    const twentyFourHoursAgo = new Timestamp(now.seconds - 24 * 60 * 60, now.nanoseconds);

    // Requête pour obtenir les réactions des dernières 24 heures
    const q = query(
      reactionsRef,
      where("timestamp", ">", twentyFourHoursAgo), // Filtre pour les dernières 24 heures
      orderBy("timestamp", "desc") // Optionnel : trier par ordre décroissant de timestamp
    );

    const querySnapshot = await getDocs(q);
    const reactions: DocumentData[] = [];

    querySnapshot.forEach((doc) => {
      reactions.push(doc.data());
    });

    return reactions;
  } catch (e) {
    console.error("Erreur lors de la récupération des réactions : ", e);
    return [];
  }
}